const $ = jQuery;
let map, bounds, infowindow, markers = [];
const resultsContainer = $("#pmp-map-results");
const weekdays = ["Mon", "Tues", "Weds", "Thurs", "Fri", "Sat", "Sun"];
const stores = JSON.parse(store_data);
const initMap = () => {
	// let centered = {lat: my_loc.lat, lng: my_loc.lng};
	const mapElem = document.getElementById("map");

	if ( mapElem ) {
		bounds = new google.maps.LatLngBounds();
		infowindow = new google.maps.InfoWindow(); 
		map = new google.maps.Map(mapElem, {
			// center: centered,
			zoom: 5,
		});

		if (stores.stores && stores.stores.length > 0 ) {
			Object.values(stores.stores).map((address, i) => {
				// console.log(address);
				let data = null;
				let openings = '';

				if (address.data) {
					data = JSON.parse(address.data);

					let markerPos = { lat: parseFloat(address.lat), lng: parseFloat(address.lng) };
					let marker = new google.maps.Marker({
						map: map,
						position: markerPos
					});

					markers.push(marker);

					bounds.extend(marker.position);

					new google.maps.event.addListener(marker, 'click', (function (marker, i) {
						return function () {
							infowindow.setContent(address.storename);
							infowindow.open(map, marker);
						}
					})(marker, i));
					
					map.fitBounds(bounds);

					if (data.opening_hours) {
						openings = '<span class="d-block opening-hours">Opening Hours</span>';
						Object.entries(data.opening_hours.periods).map((times, i) => {
							openings += `<span class="d-block">${weekdays[i]}: ${times[1]?.open?.time} - ${times[1]?.close?.time}</li>`;
						});
					}
				}

				if (data) {
					let website = '';
					if (data.website) {
						website = '<p><a href="' + data.website + '" target="_blank">Visit Website</a></p>';
					}

					let telephone = '';
					if (data.formatted_phone_number) {
						telephone = '<p><a href="tel:' + data.formatted_phone_number + '">'+data.formatted_phone_number+'</a></p>';
					}

					const distancecalc = (address.distance) ? '<span class="distance">'+address.distance+' miles from your location</span>' : '';
					const newRow = '<div class="pmp-map-result--item"><div class="row"><div class="col-12 col-md-4"><h3>' + address.storename + '</h3>'+distancecalc+'<p>' + data.formatted_address + '</p>' + telephone + website + '</div><div class="col-12 col-md-4"></div><div class="col-12 col-md-4">' + openings + '</div>';
					resultsContainer.append(newRow);
				}
			});
		} else {
			resultsContainer.append('<div class="text-center mt-4"><strong>No stores found. Please try expanding your search.</strong></div>');
		}
	}
}

function DeleteMarkers() {
	//Loop through all the markers and remove
	for (var i = 0; i < markers.length; i++) {
		markers[i].setMap(null);
	}
	markers = [];
};

$(document).ready(function () {
	if (stores.online) {
		Object.entries(stores.online).map((store, i) => {
			const listing = '<div class="col-6 col-md-2"><div class="pmp-online-results--item"><a class="external-store-link" href="' + store[1].url + '" target="_blank"><img src="' + store[1].image + '" class="img-fluid" alt="' + store[1].name + '"/><span class="d-block">Visit site</span></a></div></div>';
			$('#pmp-online-results').append(listing);
		});
	}

	if ($('select[name="store-type"] option:selected').val() === 'online') {
		$('.input-postcode').hide(0);
		$('.offline-store-text').hide(0);
		$('.online-store-text').show(0);
	} else {
		$('.online-store-text').hide(0);
		$('.offline-store-text').show(0);
	}

	$('select[name="store-type"]').on('change', function(e) {
		e.preventDefault();
		const value = $(this).find(":selected").val();
		if ( value === 'online') {
			$('.input-postcode').hide(0);
			$('.offline-store-text').hide(0);
			$('.online-store-text').show(0);
		} else {
			$('.online-store-text').hide(0);
			$('.offline-store-text').show(0);
			$('.input-postcode').show(0);
		}
	});
});

window.initMap = initMap;

$('.pmp-store-locator [name="store-type"], .pmp-store-locator [name="country"]').on('change', function (e) {
	e.preventDefault();
	get_form_data();
});

$('.pmp-store-locator form').on('submit', function (e) {
	e.preventDefault();
	get_form_data();
});

function get_form_data() {
	const storeType = $('.pmp-store-locator [name="store-type"]').find(":selected").val();
	const countryType = $('.pmp-store-locator [name="country"]').find(":selected").val();
	const postcodeInput = $('.pmp-store-locator [name="postcode"]').val();

	if (storeType === 'store') {
		$('.store-based-results').show(0);
		$('.pmp-online-results').hide(0);
		do_ajax({ type: 'store', country: countryType, postcode: postcodeInput }, 'store');
	} else {
		$('.store-based-results').hide(0);
		$('.pmp-online-results').show(0);
		do_ajax({ type: 'online', country: countryType, postcode: postcodeInput }, 'online');
	}
}


function do_ajax(newData, type = null) {
	$('.pmp-loading').show(0);

	const storeData = {
		action: 'get_store_locator',
		data: newData,
	};

	DeleteMarkers();
	$.post(ajax.url, storeData, function (response) {
		bounds = new google.maps.LatLngBounds();
		if (response.success === true) {
			let stores = JSON.parse(response.data);

			if (type === 'store') {
				resultsContainer.html('');
				// success
				if (stores.items.stores && stores.items.stores.length > 0) {
					Object.values(stores.items.stores).map((address, i) => {
						let data = JSON.parse(address.data);
						if (address.data) {
							let markerPos = { lat: parseFloat(address.lat), lng: parseFloat(address.lng) };
							let marker = new google.maps.Marker({
								map: map,
								position: markerPos
							});

							markers.push(marker);
							bounds.extend(marker.position);

							new google.maps.event.addListener(marker, 'click', (function (marker, i) {
								return function () {
									infowindow.setContent(address.storename);
									infowindow.open(map, marker);
								}
							})(marker, i));

							if (data) {
								let openings = '';
								if (data.opening_hours) {
									openings = '<span class="d-block opening-hours">Opening Hours</span>';
									Object.entries(data.opening_hours.periods).map((times, i) => {
										openings += `<span class="d-block">${weekdays[i]}: ${times[1]?.open?.time} - ${times[1]?.close?.time}</li>`;
									});
								}

								let website = '';
								if (data.website) {
									website = '<p><a href="' + data.website + '" target="_blank">Visit Website</a></p>';
								}

								let telephone = '';
								if (data.formatted_phone_number) {
									telephone = '<p><a href="tel:' + data.formatted_phone_number + '">' + data.formatted_phone_number + '</a></p>';
								}

								const distancecalc = (address.distance) ? '<span class="distance">' + address.distance + ' miles from your location</span>' : '';
								const newRow = '<div class="pmp-map-result--item"><div class="row"><div class="col-12 col-md-4"><h3>' + address.storename + '</h3>' + distancecalc + '<p>' + data.formatted_address + '</p>' + telephone + website + '</div><div class="col-12 col-md-4"></div><div class="col-12 col-md-4">' + openings + '</div>';
								resultsContainer.append(newRow);

							}
						}
					});

					setTimeout(function () { map.fitBounds(bounds); }, 100); 
					$('.pmp-loading').hide(0);
				} else {
					resultsContainer.append('<div class="text-center mt-4"><strong>No stores found. Please try expanding your search.</strong></div>');
					$('.pmp-loading').hide(0);
				}
			} else {
				$('.pmp-online-results').html('');
				if (stores.items.online) {
					Object.values(stores.items.online).map((address, i) => {
						$('.pmp-online-results').append('<div class="col-6 col-md-2"><div class="pmp-online-results--item"><a class="external-store-link" href="'+address.url+'" target="_blank"><img src="'+address.image+'" class="img-fluid" alt="'+address.name+'"><span class="d-block">Visit site</span></a></div></div>');
					});
				}
				$('.pmp-loading').hide(0);
			}
		}
	});


}


